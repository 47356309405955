import { AppProps } from "next/app";
import Head from "next/head";
import favicon from "../../public/images/favicon.ico";
import ErrorPage from "../components/ErrorPage";
import { BrandedAppProps } from "./BrandedApp";
import "react-phone-number-input/style.css";
import "../styles/reset.scss";
import "../styles/global.scss";
import "../styles/utils.scss";
import "@pushpress/shared-components/build/styles/app.global.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "@pushpress/shared-components/build/main.min.css";
import dynamic from "next/dynamic";
import BrandedMetaTags from "./BrandedMetaTags";
import { FC, Suspense } from "react";
import NonBrandedContent from "./NonBrandedContent";
import BrandedAnalyticsProvider from "../components/BrandedAnalyticsProvider";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { generateGrowthBook } from "../utils/growthBookUtils";
import AnalyticsProvider, {
  AnalyticsPlugin,
} from "../components/AnalyticsProvider";
import SegmentPlugin from "../components/AnalyticsProvider/segmentPlugin";
import HeapPlugin from "../components/AnalyticsProvider/heapPlugin";

const BrandedApp = dynamic(() => import("./BrandedApp"), {
  ssr: false,
});

const BrandedContent = dynamic(() => import("./BrandedContent"), {
  ssr: false,
});

export interface AppErrorProps {
  error: string;
}

export interface AppPageProps {
  title: string;
  favicon: string | null;
  sessionId: string;
  subdomain?: string;
  disableStripe?: boolean;
}

// init gb here
const gb = generateGrowthBook();
gb.init({
  streaming: true,
});

const segmentWriteKey = process.env.NEXT_PUBLIC_SALES_SEGMENT_WRITE_KEY || "";
const heapAnalyticsAppId = process.env.NEXT_PUBLIC_HEAP_ANALYTICS_APP_ID || "";
const plugins: AnalyticsPlugin[] = [];

if (segmentWriteKey) {
  plugins.push(new SegmentPlugin(segmentWriteKey));
}

if (heapAnalyticsAppId) {
  plugins.push(new HeapPlugin(heapAnalyticsAppId));
}

const AppPage: FC<AppProps> = ({ Component, pageProps }: any) => {
  const props = pageProps as BrandedAppProps & AppPageProps;

  // show the error page if there is an error
  if ("error" in pageProps && pageProps.error) {
    const error = pageProps.error;
    return (
      <div suppressHydrationWarning style={{ position: "relative", zIndex: 1 }}>
        <Head>
          <link rel="icon" href={favicon.src} />
          <title>PushPress</title>
        </Head>
        {typeof window === "undefined" || !window.document ? null : (
          <Suspense fallback="">
            <ErrorPage message={error} />
          </Suspense>
        )}
      </div>
    );
  }

  // documents doesn't required the app component
  const isDocumentsPage =
    "landingPagesType" in pageProps &&
    pageProps.landingPagesType === "document";
  if (isDocumentsPage) {
    return (
      <>
        <BrandedMetaTags
          title={props.title}
          favicon={props.favicon}
          sessionId={props.sessionId}
        />
        <BrandedContent pageProps={props}>
          <Component {...pageProps} />
        </BrandedContent>
      </>
    );
  }

  // if there is no client, render the default template
  if (!props.client) {
    return (
      <GrowthBookProvider growthbook={gb}>
        <AnalyticsProvider plugins={plugins}>
          <NonBrandedContent>
            <Component {...pageProps} />
          </NonBrandedContent>
        </AnalyticsProvider>
      </GrowthBookProvider>
    );
  }

  return (
    <>
      <BrandedMetaTags
        title={props.title}
        favicon={props.favicon}
        sessionId={props.sessionId}
      />
      <BrandedAnalyticsProvider
        googleAnalyticsId={props.googleAnalyticsId}
        facebookMarketingId={props.facebookMarketingId}
        landingPagesType={props.landingPagesType}
      >
        <BrandedContent pageProps={props}>
          <BrandedApp {...props}>
            <Component {...pageProps} />
          </BrandedApp>
        </BrandedContent>
      </BrandedAnalyticsProvider>
    </>
  );
};

export default AppPage;
